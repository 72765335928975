import Grid from '@mui/material/Grid';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import React, { useContext } from 'react';

import { UsersContext } from '../components/hooks/useUser';
import Section from '../components/Section';
import AccentButton from '../components/structure/AccentButton';
import { ROUTES } from '../utils/AppRoutes';

const ErrorPage = () => {
  const { t } = useTranslation('common');
  const { user } = useContext(UsersContext);

  return (
    <Section header={t('404 - Seite nicht erreichbar.')}>
      <Grid item>
        {!!user && (
          <AccentButton href={ROUTES.DASHBOARD}>{t('Dashboard')}</AccentButton>
        )}
        {!user && (
          <AccentButton href={ROUTES.HOME}>{t('Homepage')}</AccentButton>
        )}
      </Grid>
    </Section>
  );
};

export default ErrorPage;

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
    },
  };
}
